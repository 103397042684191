import { render, staticRenderFns } from "./sendDocumentOnMail.vue?vue&type=template&id=69dd4501&scoped=true"
import script from "./sendDocumentOnMail.vue?vue&type=script&lang=js"
export * from "./sendDocumentOnMail.vue?vue&type=script&lang=js"
import style1 from "./sendDocumentOnMail.vue?vue&type=style&index=1&id=69dd4501&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69dd4501",
  null
  
)

export default component.exports